import React, { useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../Images/Logo.svg";
import { NavLink, Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ChatIcon from "../Images/chatting-icon.svg";
import ContactIcon from "../Images/Contact-icon.svg";
import MeetingLogo from "../Images/meeting-icon.svg";
import NotificationLogo from "../Images/notification-icon.svg";
import UserGuidLogo from "../Images/User-GuideLogo.svg";
import ReleaseNoteLogo from "../Images/Release-Note-Logo.svg";
import { useLocation } from "react-router-dom";
import { ScrollLink } from "react-scroll";
import menue from "../Images/menu.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import { animateScroll as scrollAnimate } from "react-scroll";

function Header() {
  const location = useLocation();
  const Navigate = useNavigate();

  const [showing, setShowing] = useState(false);
  const navigate = useNavigate();
  const toggleOffcanvas = () => {
    setShowing(!showing);
  };
  const handleClose = () => setShowing(false);
  const handleShow = () => setShowing(true);

  const handleNavLinkClick = (path) => {
    console.log("showingshowingshowingshowingshowingshowingshowing");
    // setShowing(false);
    //    Navigate(path);
  };

  const [show, setShow] = useState(false);

  const [resourceshow, setResourceshow] = useState(false);

  const Resourcedropdown = (second) => {
    setResourceshow(second);
    setShowBackdrop(second);
  };
  const [showBackdrop, setShowBackdrop] = useState(false);
  const handleToggle = (isOpen) => {
    setShow(isOpen);
    setShowBackdrop(isOpen);
  };

  // const handleClick = (currentTab) => {
  //   console.log("Reached 1");

  //   if (location.pathname !== "/") {
  //     console.log("Reached 2");
  //     N("/", {
  //       state: {
  //         currentTab,
  //       },
  //     });

  //     // Delay to ensure page has Nd before scrolling
  //     setTimeout(() => {
  //       const messagingElement = document.getElementById("messaging");
  //       if (messagingElement) {
  //         scrollAnimate.scrollTo(messagingElement.offsetTop, {
  //           smooth: true,
  //           duration: 100,
  //           offset: -70,
  //         });
  //       } else {
  //         console.log("Element with id 'messaging' not found.");
  //       }
  //     }, 500);
  //   } else {
  //     console.log("Reached 3");
  //     N("/", {
  //       state: {
  //         currentTab: currentTab,
  //       },
  //     });

  //     // Scroll directly when on the same page
  //     const messagingElement = document.getElementById("messaging");
  //     if (messagingElement) {
  //       scrollAnimate.scrollTo(messagingElement.offsetTop, {
  //         smooth: true,
  //         duration: 100,
  //         offset: -70,
  //       });
  //     } else {
  //       console.log("Element with id 'messaging' not found.");
  //     }
  //   }
  // };

  const handleClick = (currentTab) => {
    console.log("Reached 1");

    if (location.pathname !== "/") {
      console.log("Reached 2");
      Navigate("/?" + "active_tab=" + currentTab);
      // setTimeout(() => {
      //   scrollAnimate.scrollTo(document.getElementById("messaging").offsetTop, {
      //     smooth: true,
      //     duration: 100,
      //     offset: -70,
      //   });
      // }, 500);
    } else {
      console.log("Reached 3");
      Navigate("/?" + "active_tab=" + currentTab);
      // scrollAnimate.scrollTo(document.getElementById("messaging").offsetTop, {
      //   smooth: true,
      //   duration: 100,
      //   offset: -70,
      // });
    }
  };

  const handleClickMeeting = (secondTab) => {
    console.log("Reached 1");

    if (location.pathname !== "/") {
      console.log("Reached 2");
      Navigate("/?" + "active_tab=" + secondTab);
      // setTimeout(() => {
      //   scrollAnimate.scrollTo(document.getElementById("messaging").offsetTop, {
      //     smooth: true,
      //     duration: 100,
      //     offset: -70,
      //   });
      // }, 500);
    } else {
      console.log("Reached 3");
      Navigate("/?" + "active_tab=" + secondTab);
      // scrollAnimate.scrollTo(document.getElementById("messaging").offsetTop, {
      //   smooth: true,
      //   duration: 100,
      //   offset: -70,
      // });
    }
  };

  const handleClickOrganized = (thirdTab) => {
    console.log("Reached 1");

    if (location.pathname !== "/") {
      console.log("Reached 2");
      Navigate("/?" + "active_tab=" + thirdTab);
      // setTimeout(() => {
      //   scrollAnimate.scrollTo(document.getElementById("messaging").offsetTop, {
      //     smooth: true,
      //     duration: 100,
      //     offset: -70,
      //   });
      // }, 500);
    } else {
      console.log("Reached 3");
      Navigate("/?" + "active_tab=" + thirdTab);
      // scrollAnimate.scrollTo(document.getElementById("messaging").offsetTop, {
      //   smooth: true,
      //   duration: 100,
      //   offset: -70,
      // });
    }
  };

  const handleClickNotification = (fourthTab) => {
    console.log("Reached 1");

    if (location.pathname !== "/") {
      console.log("Reached 2");
      Navigate("/?" + "active_tab=" + fourthTab);
      // setTimeout(() => {
      //   scrollAnimate.scrollTo(document.getElementById("messaging").offsetTop, {
      //     smooth: true,
      //     duration: 100,
      //     offset: -70,
      //   });
      // }, 500);
    } else {
      console.log("Reached 3");
      Navigate("/?" + "active_tab=" + fourthTab);
      // scrollAnimate.scrollTo(document.getElementById("messaging").offsetTop, {
      //   smooth: true,
      //   duration: 100,
      //   offset: -70,
      // });
    }
  };

  // const handleClickNotification = (fourthTab) => {
  //   console.log("Reached 1");

  //   const currentParams = new URLSearchParams(location.search);
  //   currentParams.set("active_tab", fourthTab); // Update the active_tab in the URL query params

  //   const newUrl = "/?" + currentParams.toString();

  //   if (location.pathname !== "/") {
  //     // If the user is not on the path, navigate
  //     console.log("Reached 2");
  //     Navigate(newUrl);
  //   } else {
  //     // If the user is already on the path, update the query params and scroll
  //     console.log("Reached 3");
  //     window.history.replaceState(null, "", newUrl);

  //     // Scroll smoothly to the section
  //     setTimeout(() => {
  //       scrollAnimate.scrollTo(
  //         document.getElementById("left-tabs-example")?.offsetTop,
  //         {
  //           smooth: true,
  //           duration: 100,
  //           offset: -70,
  //         }
  //       );
  //     }, 500);
  //   }
  // };

  // const handleClickNotification = (fourthTab) => {
  //   console.log("Reached 1");

  //   if (location.pathname !== "/") {
  //     console.log("Reached 2");
  //     Navigate("/?" + "active_tab=" + fourthTab);
  //     // setTimeout(() => {
  //     //   scrollAnimate.scrollTo(document.getElementById("messaging").offsetTop, {
  //     //     smooth: true,
  //     //     duration: 100,
  //     //     offset: -70,
  //     //   });
  //     // }, 500);
  //   } else {
  //     console.log("Reached 3");
  //     Navigate("/?" + "active_tab=" + fourthTab);
  //     // scrollAnimate.scrollTo(document.getElementById("messaging").offsetTop, {
  //     //   smooth: true,
  //     //   duration: 100,
  //     //   offset: -70,
  //     // });
  //   }
  // };

  const Contact = () => {
    setTimeout(() => {
      scrollAnimate.scrollTo(
        document.getElementById("Contact-page").offsetTop,
        {
          smooth: true,
          duration: 100,
          offset: -70,
        }
      );
    }, 500);
    setShowing(false);
  };
  useEffect(() => {
    handleClose();
  }, [location]);

  return (
    <div className="header-bg sticky-header">
      <div className="container-fluid container-mar py-2">
        <Navbar expand="lg" expanded={showing}>
          <Navbar.Brand href="#">
            <NavLink to="/">
              <img className="logo-width" src={Logo} alt="" />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar-expand-lg"
            onClick={handleShow}
          >
            <img src={menue} alt="" />
          </Navbar.Toggle>
          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-lg"
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
            placement="end"
            show={showing}
            onHide={toggleOffcanvas}
            className="offcanvas-no-animation"
          >
            <Offcanvas.Header
              // className="pt-4"
              style={{ paddingTop: "1.3rem" }}
              closeButton
              onClick={toggleOffcanvas}
            >
              <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg header-bg">
                <img className="logo-width" src={Logo} alt="" />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav
                  className=" w-100 d-flex justify-content-center text-center"
                  style={{ maxHeight: "100px" }}
                  navbarScroll
                >
                  <div>
                    <NavLink
                      className=" nav-link white-color mob-float-start "
                      to="/"
                      activeClassName="active-link"
                      onClick={() => handleNavLinkClick("/")}
                      // activeClassName="active "
                    >
                      <span className="white-color mob-5 dropdown-hover">
                        Start
                      </span>
                    </NavLink>
                  </div>
                  {showBackdrop && <div className="backdrop"></div>}

                  <NavDropdown
                    className="dropdown-hover cus-mx-5  dropdown-hover mob-float-start"
                    show={show}
                    onToggle={handleToggle}
                    title={
                      <span
                        className={`dropdown-hover ${
                          show ? "text-color" : "white-color"
                        }`}
                      >
                        Highlights{" "}
                        {show ? (
                          <svg
                            className="ms-2"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L7 7L13 1"
                              stroke="#ffe277"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="ms-2 arrow-btn"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L7 7L13 1"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        )}
                      </span>
                    }
                    id="basic-nav-dropdown"
                    // onMouseEnter={handleMouseEnter}
                    // onMouseLeave={handleMouseLeave}
                    // show={toggledropdow}
                    // onToggle={handleDropdown}
                  >
                    <div className="diplay-flex">
                      <div>
                        <NavDropdown.Item>
                          <div
                            // onClick={closeNav}
                            // className="  header-text mx-2 nav-link Feather-font-bold"
                            // className={({ isActive, isPending }) =>
                            //   `${
                            //     isPending ? "pending" : isActive ? "active" : ""
                            //   } header-text mx-2 nav-link Feather-font-bold`
                            // }
                            // to="/"
                            smooth={true}
                            duration={100}
                            offset={-70}
                            activeClassName="active "
                            onClick={() => handleClick("instant_messaging")}
                            // activeClassName="active-link"
                          >
                            <div className="d-flex">
                              <img
                                className="cus-img-fluid"
                                src={ChatIcon}
                                alt=""
                              />

                              <div className="align-center p-mar">
                                <p className="ms-1 user">
                                  <span className="normal-font-size white-color">
                                    Instant Messaging
                                  </span>
                                  <span className="ps-1">
                                    {" "}
                                    <svg
                                      width="15"
                                      height="17"
                                      viewBox="0 0 15 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.54834 4.24207L13.3566 10.0503L7.54834 15.8585"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M13.3566 10.0501L1 10.0501"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    <div className="font-16 white-color">
                                      Send & receive messages instantly.
                                    </div>
                                  </span>
                                </p>{" "}
                              </div>
                            </div>
                          </div>
                        </NavDropdown.Item>

                        <NavDropdown.Item>
                          <div
                            smooth={true}
                            duration={100}
                            offset={-70}
                            activeClassName="active "
                            onClick={() =>
                              handleClickMeeting("seamless_meetings")
                            }
                          >
                            <div className="d-flex">
                              <img
                                className="cus-img-fluid"
                                src={MeetingLogo}
                                alt=""
                              />

                              <div className="align-center p-mar">
                                <p className="ms-1 user">
                                  <span className="normal-font-size white-color">
                                    Seamless Meetings
                                  </span>
                                  <span className="ps-1">
                                    {" "}
                                    <svg
                                      width="15"
                                      height="17"
                                      viewBox="0 0 15 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.54834 4.24207L13.3566 10.0503L7.54834 15.8585"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M13.3566 10.0501L1 10.0501"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    <div className="font-16 white-color">
                                      Smooth, hassle-free virtual meetings.
                                    </div>
                                  </span>
                                </p>{" "}
                              </div>
                            </div>
                          </div>
                        </NavDropdown.Item>
                      </div>
                      <div>
                        <NavDropdown.Item>
                          <div
                            smooth={true}
                            duration={100}
                            offset={-70}
                            activeClassName="active "
                            onClick={() =>
                              handleClickOrganized("organized_channels")
                            }
                          >
                            <div className="d-flex">
                              <img
                                className="cus-img-fluid"
                                src={ContactIcon}
                                alt=""
                              />

                              <div className="align-center p-mar">
                                <p className="ms-1 user">
                                  <span className="normal-font-size white-color">
                                    Organized Channels
                                  </span>
                                  <span className="ps-1">
                                    {" "}
                                    <svg
                                      width="15"
                                      height="17"
                                      viewBox="0 0 15 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.54834 4.24207L13.3566 10.0503L7.54834 15.8585"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M13.3566 10.0501L1 10.0501"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    <div className="font-16 white-color">
                                      Keep conversations structured & on topic.
                                    </div>
                                  </span>
                                </p>{" "}
                              </div>
                            </div>
                          </div>
                        </NavDropdown.Item>

                        <NavDropdown.Item>
                          <div
                            smooth={true}
                            duration={100}
                            offset={-70}
                            activeClassName="active "
                            onClick={() =>
                              handleClickNotification("smart_notification")
                            }
                          >
                            <div className="d-flex">
                              <img
                                className="cus-img-fluid"
                                src={NotificationLogo}
                                alt=""
                              />

                              <div className="align-center">
                                <p className="ms-1 user">
                                  <span className="normal-font-size white-color">
                                    Smart Notification
                                  </span>
                                  <span className="ps-1">
                                    {" "}
                                    <svg
                                      width="15"
                                      height="17"
                                      viewBox="0 0 15 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.54834 4.24207L13.3566 10.0503L7.54834 15.8585"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M13.3566 10.0501L1 10.0501"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    <div className="font-16 white-color">
                                      Get notified about what matters most.
                                    </div>
                                  </span>
                                </p>{" "}
                              </div>
                            </div>
                          </div>
                        </NavDropdown.Item>
                      </div>
                    </div>
                  </NavDropdown>

                  <NavDropdown
                    className="dropdown-hover mx-2 drop-down-bs  dropdown-hover mob-float-start"
                    resourceshow={resourceshow}
                    onToggle={Resourcedropdown}
                    title={
                      <span
                        className={`dropdown-hover ${
                          resourceshow ? "text-color" : "white-color"
                        }`}
                      >
                        Resources{" "}
                        {resourceshow ? (
                          <svg
                            className="ms-2"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L7 7L13 1"
                              stroke="#ffe277"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="ms-2 arrow-btn"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L7 7L13 1"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        )}
                      </span>
                    }
                    id="basic-nav-dropdown"
                    // onMouseEnter={handleMouseEnter}
                    // onMouseLeave={handleMouseLeave}
                    // show={toggledropdow}
                    // onToggle={handleDropdown}
                  >
                    <div className="diplay-flex">
                      <div>
                        <NavDropdown.Item>
                          <NavLink
                            // onClick={closeNav}
                            // className="  header-text mx-2 nav-link Feather-font-bold"
                            // className={({ isActive, isPending }) =>
                            //   `${
                            //     isPending ? "pending" : isActive ? "active" : ""
                            //   } header-text mx-2 nav-link Feather-font-bold`
                            // }
                            to="/user-guide"
                            // activeClassName="active-link"
                          >
                            <div className="d-flex">
                              <img
                                className="cus-img-fluid white-color"
                                src={UserGuidLogo}
                                alt=""
                              />

                              <div className="align-center p-mar">
                                <p className="ms-1 user  ">
                                  <span className="normal-font-size white-color text-dec">
                                    User Guide
                                  </span>
                                  <span className="ps-1">
                                    {" "}
                                    <svg
                                      width="15"
                                      height="17"
                                      viewBox="0 0 15 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.54834 4.24207L13.3566 10.0503L7.54834 15.8585"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M13.3566 10.0501L1 10.0501"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    <div className="white-color font-16">
                                      Quick steps to use product our
                                      effectively.
                                    </div>
                                  </span>
                                </p>{" "}
                              </div>
                            </div>
                          </NavLink>
                        </NavDropdown.Item>
                      </div>
                      <div>
                        <NavDropdown.Item>
                          <NavLink to="/release-notes">
                            <div className="d-flex">
                              <img
                                className="cus-img-fluid"
                                src={ReleaseNoteLogo}
                                alt=""
                              />

                              <div className="align-center p-mar">
                                <p className="ms-1 user">
                                  <span className="normal-font-size white-color text-dec">
                                    Release Note
                                  </span>
                                  <span className="ps-1">
                                    {" "}
                                    <svg
                                      width="15"
                                      height="17"
                                      viewBox="0 0 15 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.54834 4.24207L13.3566 10.0503L7.54834 15.8585"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M13.3566 10.0501L1 10.0501"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    <div className="white-color font-16">
                                      What's new and improved in the latest
                                      update.
                                    </div>
                                  </span>
                                </p>{" "}
                              </div>
                            </div>
                          </NavLink>
                        </NavDropdown.Item>
                      </div>
                    </div>
                  </NavDropdown>
                </Nav>

                <Button
                  variant="outline-success"
                  className="btn custom-talk-btn res-mar-top"
                  onClick={Contact}
                >
                  Let’s Talk
                </Button>
              </Navbar.Collapse>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar>

        {/* <Navbar expand="lg">
            <Navbar.Brand href="#">
              <NavLink to="/">
                <img className="logo-width" src={Logo} alt="" />
              </NavLink>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <img src={menue} alt="" />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav
                className=" w-100 d-flex justify-content-center text-center"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <div>
                  <NavLink
                    className=" nav-link white-color"
                    to="/"
                    activeClassName="active-link"
                    // activeClassName="active "
                  >
                    <span className="white-color  dropdown-hover">Start</span>
                  </NavLink>
                </div>
                <NavDropdown
                  className="dropdown-hover cus-mx-5  dropdown-hover"
                  show={show}
                  onToggle={handleToggle}
                  title={
                    <span
                      className={`dropdown-hover ${
                        show ? "text-color" : "white-color"
                      }`}
                    >
                      Highlights{" "}
                      {show ? (
                        <svg
                          className="ms-2"
                          width="14"
                          height="8"
                          viewBox="0 0 14 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L7 7L13 1"
                            stroke="#ffe277"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="ms-2 arrow-btn"
                          width="14"
                          height="8"
                          viewBox="0 0 14 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L7 7L13 1"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      )}
                    </span>
                  }
                  id="basic-nav-dropdown"
                  // onMouseEnter={handleMouseEnter}
                  // onMouseLeave={handleMouseLeave}
                  // show={toggledropdow}
                  // onToggle={handleDropdown}
                >
                  <div className="diplay-flex">
                    <div>
                      <NavDropdown.Item>
                        <div
                          // onClick={closeNav}
                          // className="  header-text mx-2 nav-link Feather-font-bold"
                          // className={({ isActive, isPending }) =>
                          //   `${
                          //     isPending ? "pending" : isActive ? "active" : ""
                          //   } header-text mx-2 nav-link Feather-font-bold`
                          // }
                          // to="/"
                          smooth={true}
                          duration={100}
                          offset={-70}
                          activeClassName="active "
                          onClick={() => handleClick("instant_messaging")}
                          // activeClassName="active-link"
                        >
                          <div className="d-flex">
                            <div>
                              <img
                                className="cus-img-fluid"
                                src={ChatIcon}
                                alt=""
                              />
                            </div>
                            <div className="align-center">
                              <span className="ms-1 user">
                                <span className="normal-font-size">
                                  Instant Messaging
                                </span>
                                <span className="ps-1">
                                  {" "}
                                  <svg
                                    width="15"
                                    height="17"
                                    viewBox="0 0 15 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.54834 4.24207L13.3566 10.0503L7.54834 15.8585"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M13.3566 10.0501L1 10.0501"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <div className="font-16">
                                    Send & receive messages instantly.
                                  </div>
                                </span>
                              </span>{" "}
                            </div>
                          </div>
                        </div>
                      </NavDropdown.Item>

                      <NavDropdown.Item>
                        <div
                          smooth={true}
                          duration={100}
                          offset={-70}
                          activeClassName="active "
                          onClick={() => handleClickMeeting("seamless_meetings")}
                        >
                          <div className="d-flex">
                            <div>
                              <img
                                className="cus-img-fluid"
                                src={MeetingLogo}
                                alt=""
                              />
                            </div>
                            <div className="align-center">
                              <span className="ms-1 user">
                                <span className="normal-font-size">
                                  Seamless Meetings
                                </span>
                                <span className="ps-1">
                                  {" "}
                                  <svg
                                    width="15"
                                    height="17"
                                    viewBox="0 0 15 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.54834 4.24207L13.3566 10.0503L7.54834 15.8585"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M13.3566 10.0501L1 10.0501"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <div className="font-16">
                                    Smooth, hassle-free virtual meetings.
                                  </div>
                                </span>
                              </span>{" "}
                            </div>
                          </div>
                        </div>
                      </NavDropdown.Item>
                    </div>
                    <div>
                      <NavDropdown.Item>
                        <div
                          smooth={true}
                          duration={100}
                          offset={-70}
                          activeClassName="active "
                          onClick={() =>
                            handleClickOrganized("organized_channels")
                          }
                        >
                          <div className="d-flex">
                            <div>
                              <img
                                className="cus-img-fluid"
                                src={ContactIcon}
                                alt=""
                              />
                            </div>
                            <div className="align-center">
                              <span className="ms-1 user">
                                <span className="normal-font-size">
                                  Organized Channels
                                </span>
                                <span className="ps-1">
                                  {" "}
                                  <svg
                                    width="15"
                                    height="17"
                                    viewBox="0 0 15 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.54834 4.24207L13.3566 10.0503L7.54834 15.8585"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M13.3566 10.0501L1 10.0501"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <div className="font-16">
                                    Keep conversations structured & on topic.
                                  </div>
                                </span>
                              </span>{" "}
                            </div>
                          </div>
                        </div>
                      </NavDropdown.Item>

                      <NavDropdown.Item>
                        <div
                          smooth={true}
                          duration={100}
                          offset={-70}
                          activeClassName="active "
                          onClick={() =>
                            handleClickNotification("smart_notification")
                          }
                        >
                          <div className="d-flex">
                            <div>
                              <img
                                className="cus-img-fluid"
                                src={NotificationLogo}
                                alt=""
                              />
                            </div>
                            <div className="align-center">
                              <span className="ms-1 user">
                                <span className="normal-font-size">
                                  Smart Notification
                                </span>
                                <span className="ps-1">
                                  {" "}
                                  <svg
                                    width="15"
                                    height="17"
                                    viewBox="0 0 15 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.54834 4.24207L13.3566 10.0503L7.54834 15.8585"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M13.3566 10.0501L1 10.0501"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <div className="font-16">
                                    Get notified about what matters most.
                                  </div>
                                </span>
                              </span>{" "}
                            </div>
                          </div>
                        </div>
                      </NavDropdown.Item>
                    </div>
                  </div>
                </NavDropdown>

                <NavDropdown
                  className="dropdown-hover mx-2  dropdown-hover"
                  resourceshow={resourceshow}
                  onToggle={Resourcedropdown}
                  title={
                    <span
                      className={`dropdown-hover ${
                        resourceshow ? "text-color" : "white-color"
                      }`}
                    >
                      Resources{" "}
                      {resourceshow ? (
                        <svg
                          className="ms-2"
                          width="14"
                          height="8"
                          viewBox="0 0 14 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L7 7L13 1"
                            stroke="#ffe277"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="ms-1 arrow-btn"
                          width="14"
                          height="8"
                          viewBox="0 0 14 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L7 7L13 1"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      )}
                    </span>
                  }
                  id="basic-nav-dropdown"
                  // onMouseEnter={handleMouseEnter}
                  // onMouseLeave={handleMouseLeave}
                  // show={toggledropdow}
                  // onToggle={handleDropdown}
                >
                  <div className="diplay-flex">
                    <div>
                      <NavDropdown.Item>
                        <NavLink
                          // onClick={closeNav}
                          // className="  header-text mx-2 nav-link Feather-font-bold"
                          // className={({ isActive, isPending }) =>
                          //   `${
                          //     isPending ? "pending" : isActive ? "active" : ""
                          //   } header-text mx-2 nav-link Feather-font-bold`
                          // }
                          to="/user-guide"
                          // activeClassName="active-link"
                        >
                          <div className="d-flex">
                            <div>
                              <img
                                className="cus-img-fluid"
                                src={UserGuidLogo}
                                alt=""
                              />
                            </div>
                            <div className="align-center">
                              <span className="ms-1 user ">
                                <span>User Guide</span>
                                <span className="ps-1">
                                  {" "}
                                  <svg
                                    width="15"
                                    height="17"
                                    viewBox="0 0 15 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.54834 4.24207L13.3566 10.0503L7.54834 15.8585"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M13.3566 10.0501L1 10.0501"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <div>
                                    Quick steps to use product our effectively.
                                  </div>
                                </span>
                              </span>{" "}
                            </div>
                          </div>
                        </NavLink>
                      </NavDropdown.Item>
                    </div>
                    <div>
                      <NavDropdown.Item>
                        <NavLink to="/release-notes">
                          <div className="d-flex">
                            <div>
                              <img
                                className="cus-img-fluid"
                                src={ReleaseNoteLogo}
                                alt=""
                              />
                            </div>
                            <div className="align-center">
                              <span className="ms-1 user">
                                <span>Release Note</span>
                                <span className="ps-1">
                                  {" "}
                                  <svg
                                    width="15"
                                    height="17"
                                    viewBox="0 0 15 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.54834 4.24207L13.3566 10.0503L7.54834 15.8585"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M13.3566 10.0501L1 10.0501"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <div>
                                    What's new and improved in the latest update.
                                  </div>
                                </span>
                              </span>{" "}
                            </div>
                          </div>
                        </NavLink>
                      </NavDropdown.Item>
                    </div>
                  </div>
                </NavDropdown>
              </Nav>

              <Button
                variant="outline-success"
                className="btn custom-talk-btn"
                onClick={Contact}
              >
                Let’s Talk
              </Button>
            </Navbar.Collapse>
          </Navbar> */}
      </div>
    </div>
  );
}

export default Header;
