// import React from "react";
// import ChatBot from "react-chatbotify";
// // import HeaderLogo from "./HeaderLogo";

// const MyChatBot = () => {
//   const [form, setForm] = React.useState({});
//   const [availableOptions, setAvailableOptions] = React.useState([
//     "Is CollabUp free to use?",
//     "Who can use CollabUp? ",
//     "What features does CollabUp offer?",
//     "Can I share files and presentations? ",
//     "Does CollabUp support screen recording? ",
//   ]);
//   const [showOptions, setShowOptions] = React.useState(true);

//   const formStyle = {
//     marginTop: 10,
//     marginLeft: 20,
//     border: "1px solid #491d8d",
//     padding: 10,
//     maxWidth: 300,
//   };

//   function handleUserInput(path, userInput, nextPath, nextMessage) {
//     setForm((prevForm) => ({
//       ...prevForm,
//       [path]: userInput,
//     }));
//     return {
//       path: nextPath,
//       message: nextMessage,
//     };
//   }

//   const flow = {
//     start: {
//       message: `Hi 👋, this is our virtual assistant!
//         Tell us how we can help.`,
//       options: availableOptions,
//       path: "process_options",
//     },

//     unknown_input: {
//       message: `Thank you for reaching out! Our sales team will contact you within 24 hours. For immediate assistance, please email us admin@lathran.com.`,

//       options: availableOptions,
//       transition: { duration: 0 },
//     },

//     process_options: {
//       transition: { duration: 0 },
//       chatDisabled: true,
//       path: async (params) => {
//         let responseMessage = "";
//         switch (params.userInput) {
//           case "Is CollabUp free to use?":
//             responseMessage =
//               "Yes, CollabUp is completely free for all users, whether for business or personal use.";
//             break;
//           case "Who can use CollabUp?":
//             responseMessage =
//               "It’s built for business teams but works equally well for personal communication.";
//             break;
//           case "What features does CollabUp offer?":
//             responseMessage =
//               "CollabUp includes channels for organized conversations, real-time messaging, audio messages, file sharing, presentations, screen recordings, and replies—all happening inside the chat.  ";
//             break;
//           case "Can I share files and presentations?":
//             responseMessage =
//               "Absolutely! You can share files, images, and even present right inside the chat to keep everyone on the same page.";
//             break;
//           case "Does CollabUp support screen recording?":
//             responseMessage =
//               "Yes, CollabUp allows you to record your screen and share it directly in chats for smoother collaboration.  ";
//             break;
//           default:
//             return "unknown_input";
//         }

//         await params.injectMessage(responseMessage);

//         setAvailableOptions((prevOptions) =>
//           prevOptions.filter((option) => option !== params.userInput)
//         );

//         setShowOptions(true);
//         return "repeat";
//       },
//     },
//     repeat: {
//       message: "Do you need any other help?",

//       options: availableOptions,
//       path: "process_options",
//     },
//   };

//   return (
//     <ChatBot
//       settings={{
//         general: { embedded: true },
//         // chatHistory: { storageKey: "example_faq_bot" },
//       }}
//       flow={flow}
//       showOptions={showOptions}
//     />
//   );
// };

// export default MyChatBot;

// import React, { useState } from "react";
// import ChatBot from "react-chatbotify";

// const MyChatBot = () => {
//   const initialOptions = [
//     "Is CollabUp free to use?",
//     "Who can use CollabUp? ",
//     "What features does CollabUp offer?",
//     "Can I share files and presentations? ",
//     "Does CollabUp support screen recording? ",
//   ];
//   const [helpOptions, setHelpOptions] = useState(initialOptions);

//   const flow = {
//     start: {
//       message: "Hi 👋, this is our virtual assistant! Tell us how we can help.",
//       transition: { duration: 1000 },
//       path: "show_options",
//     },
//     show_options: {
//       message: "Do you need any other help?",
//       options: helpOptions,
//       path: "process_options",
//     },
//     prompt_again: {
//       message: "Do you need any other help?",
//       options: helpOptions,
//       path: "process_options",
//     },
//     unknown_input: {
//       message:
//         "Thank you for reaching out! Our sales team will contact you within 24 hours. For immediate assistance, please email us admin@lathran.com.",
//       options: helpOptions,
//       path: "process_options",
//     },
//     process_options: {
//       transition: { duration: 0 },
//       chatDisabled: true,
//       path: async (params) => {
//         let responseMessage = "";
//         switch (params.userInput) {
//           case "Is CollabUp free to use?":
//             responseMessage =
//               "Yes, CollabUp is completely free for all users, whether for business or personal use.";
//             break;
//           case "Who can use CollabUp?":
//             responseMessage =
//               "It’s built for business teams but works equally well for personal communication.";
//             break;
//           case "What features does CollabUp offer?":
//             responseMessage =
//               "CollabUp includes channels for organized conversations, real-time messaging, audio messages, file sharing, presentations, screen recordings, and replies—all happening inside the chat.";
//             break;
//           case "Can I share files and presentations?":
//             responseMessage =
//               "Absolutely! You can share files, images, and even present right inside the chat to keep everyone on the same page.";
//             break;
//           case "Does CollabUp support screen recording?":
//             responseMessage =
//               "Yes, CollabUp allows you to record your screen and share it directly in chats for smoother collaboration.";
//             break;
//           default:
//             return "unknown_input";
//         }

//         await params.injectMessage(responseMessage);
//         setHelpOptions((prevOptions) =>
//           prevOptions.filter((option) => option !== params.userInput)
//         );
//         return "repeat";
//       },
//     },
//     repeat: {
//       transition: { duration: 3000 },
//       path: "prompt_again",
//     },
//   };

//   return (
//     <ChatBot
//       settings={{
//         general: { embedded: true },
//         chatHistory: { storageKey: "example_faq_bot" },
//       }}
//       flow={flow}
//     />
//   );
// };

// export default MyChatBot;

import React, { useState } from "react";
import ChatBot from "react-chatbotify";

const MyChatBot = () => {
  const initialOptions = [
    "Is CollabUp free to use?",
    "Who can use CollabUp?",
    "What features does CollabUp offer?",
    "Can I share files and presentations?",
    "Does CollabUp support screen recording?",
  ];
  const [helpOptions, setHelpOptions] = useState(initialOptions);

  const flow = {
    start: {
      message: "Hi 👋, this is our virtual assistant! Tell us how we can help.",
      transition: { duration: 1000 },
      path: "show_options",
    },
    show_options: {
      message: "Do you need any other help?",
      options: helpOptions,
      path: "process_options",
    },
    prompt_again: {
      message: "Do you need any other help?",
      options: helpOptions,
      path: "process_options",
    },
    unknown_input: {
      message:
        "Thank you for reaching out! Our sales team will contact you within 24 hours. For immediate assistance, please email us at admin@lathran.com.",
      options: helpOptions,
      path: "process_options",
    },
    process_options: {
      transition: { duration: 0 },
      chatDisabled: true,
      path: async (params) => {
        let responseMessage = "";
        switch (params.userInput) {
          case "Is CollabUp free to use?":
            responseMessage =
              "Yes, CollabUp is completely free for all users, whether for business or personal use.";
            break;
          case "Who can use CollabUp?":
            responseMessage =
              "It’s built for business teams but works equally well for personal communication.";
            break;
          case "What features does CollabUp offer?":
            responseMessage =
              "CollabUp includes channels for organized conversations, real-time messaging, audio messages, file sharing, presentations, screen recordings, and replies—all happening inside the chat.";
            break;
          case "Can I share files and presentations?":
            responseMessage =
              "Absolutely! You can share files, images, and even present right inside the chat to keep everyone on the same page.";
            break;
          case "Does CollabUp support screen recording?":
            responseMessage =
              "Yes, CollabUp allows you to record your screen and share it directly in chats for smoother collaboration.";
            break;
          default:
            return "unknown_input";
        }

        await params.injectMessage(responseMessage);
        setHelpOptions((prevOptions) =>
          prevOptions.filter((option) => option !== params.userInput)
        );
        return "repeat";
      },
    },
    repeat: {
      transition: { duration: 3000 },
      path: "prompt_again",
    },
  };
  const [chatVisible, setChatVisible] = useState(false);

  const Clickchat = () => {
    // setChatVisible(!chatVisible);
    // if (!chatVisible) {
    //   document.body.classList.add("fixed");
    // } else {
    //   document.body.classList.remove("fixed");
    // }
    // event.preventDefault();
    setChatVisible(!chatVisible);
    // if (window.innerWidth <= 786) {
    // if (!chatVisible) {
    //   setScrollPosition(window.scrollY);
    // }
    // setChatVisible(!chatVisible);
    // setTimeout(() => {
    //   window.scrollTo(0, scrollPosition);
    // }, 0);

    // setChatVisible(!chatVisible);
    // }
  };

  return (
    <div>
      <div className="chat-icon" onClick={Clickchat}>
        {chatVisible ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="lucide lucide-x"
          >
            <path d="M18 6 6 18" />
            <path d="m6 6 12 12" />
          </svg>
        ) : (
          <svg
            width="32"
            height="32"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M41.8332 13.7083C41.8332 6.22947 35.7704 0.166664 28.2915 0.166664C23.9522 0.166664 20.0896 2.21312 17.6113 5.39001C28.1813 5.79867 36.6248 14.4966 36.6248 25.1667C36.6248 25.3965 36.6209 25.6254 36.6131 25.8533L37.3054 26.0385C39.3186 26.5772 41.1603 24.7354 40.6217 22.7222L40.3563 21.7304C40.142 20.9293 40.2708 20.0829 40.6154 19.3286C41.3974 17.6166 41.8332 15.7134 41.8332 13.7083Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M33.4998 25.1667C33.4998 34.3714 26.0379 41.8333 16.8332 41.8333C14.2592 41.8333 11.8215 41.2498 9.64516 40.2079C8.89715 39.8498 8.0497 39.7241 7.24856 39.9384L4.69431 40.6218C2.68112 41.1605 0.839326 39.3187 1.37798 37.3055L2.06141 34.7513C2.27577 33.9501 2.15003 33.1027 1.79192 32.3547C0.749995 30.1783 0.166504 27.7406 0.166504 25.1667C0.166504 15.9619 7.62843 8.5 16.8332 8.5C26.0379 8.5 33.4998 15.9619 33.4998 25.1667ZM9.5415 27.25C10.6921 27.25 11.6248 26.3173 11.6248 25.1667C11.6248 24.0161 10.6921 23.0833 9.5415 23.0833C8.39091 23.0833 7.45817 24.0161 7.45817 25.1667C7.45817 26.3173 8.39091 27.25 9.5415 27.25ZM16.8332 27.25C17.9838 27.25 18.9165 26.3173 18.9165 25.1667C18.9165 24.0161 17.9838 23.0833 16.8332 23.0833C15.6826 23.0833 14.7498 24.0161 14.7498 25.1667C14.7498 26.3173 15.6826 27.25 16.8332 27.25ZM24.1248 27.25C25.2754 27.25 26.2082 26.3173 26.2082 25.1667C26.2082 24.0161 25.2754 23.0833 24.1248 23.0833C22.9742 23.0833 22.0415 24.0161 22.0415 25.1667C22.0415 26.3173 22.9742 27.25 24.1248 27.25Z"
              fill="white"
            />
          </svg>
        )}
      </div>
      <div>
        {chatVisible && (
          <div
            className="react-chatbot-kit-chat-container show-chat"
            style={{ marginTop: "-27px" }}
          >
            <ChatBot
              settings={{
                general: { embedded: true },
                chatHistory: { storageKey: "example_faq_bot" },
              }}
              flow={flow}
            />

            {/* <Chatbot
                config={config}
                messageParser={MessageParser}
                actionProvider={ActionProvider}
                // placeholderIcon=""
                runInitialMessagesWithHistory
                disableScrollToBottom
              /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyChatBot;
