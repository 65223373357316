import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import MyChatBot from "../Componentd/MyChatBot";
import { animateScroll as scrollAnimate } from "react-scroll";

function TermsCondition() {
  const [activeLink, setActiveLink] = useState(null);

  const ClickInformation = (sectionId, LinkId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const sectionOffset = section.offsetTop;
      scrollAnimate.scrollTo(sectionOffset, {
        smooth: true,
        duration: 500,
        offset: -70,
      });
      setActiveLink(LinkId);
    }
  };

  const { pathname } = useLocation();
  const [chatVisible, setChatVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="header-bg" id="top">
      <div className="container-fluid container-mar releaase-notes-bottom-margin">
        <MyChatBot />
        <div className="text-center padding-top-4">
          <h1 className="font-64 white-color">Terms & Condition</h1>
          <p className="font-size white-color mar-top-2">
            Last updated: September 2nd, 2024
          </p>
        </div>
        <hr className="white-color mar-top-3" />
        <div className="row ">
          <div className="col-md-4">
            <div className="privacy-border  mar-top-3">
              <p
                className="card-h-font-size text-color font-weight-700 cursor-pointer"
                onClick={() => ClickInformation("Information", 1)}
                style={{ color: activeLink === 1 ? "#ffe277" : "white" }}
              >
                1. Information
              </p>
              <div className="white-color">
                <p
                  className="normal-font-size  font-weight-600 cursor-pointer"
                  onClick={() => ClickInformation("Information2", 2)}
                  style={{ color: activeLink === 2 ? "#ffe277" : "white" }}
                >
                  2. Acceptable Use of Services
                </p>

                <p
                  className="normal-font-size font-weight-600 cursor-pointer"
                  onClick={() => ClickInformation("Information3", 3)}
                  style={{ color: activeLink === 3 ? "#ffe277" : "white" }}
                >
                  3. User Responsibilities
                </p>
                <p
                  className="normal-font-size  font-weight-600 cursor-pointer"
                  onClick={() => ClickInformation("Information4", 4)}
                  style={{ color: activeLink === 4 ? "#ffe277" : "white" }}
                >
                  4. Content and Intellectual Property
                </p>
                <div className="cus-p">
                  <p
                    className="font-16 ps-3 font-weight-400 cursor-pointer"
                    onClick={() => ClickInformation("Information4.1", 4.1)}
                    style={{ color: activeLink === 4.1 ? "#ffe277" : "white" }}
                  >
                    4.1 Your Content
                  </p>
                </div>
                <p
                  className="font-16 ps-3 font-weight-400 cursor-pointer"
                  onClick={() => ClickInformation("Information4.2", 4.2)}
                  style={{ color: activeLink === 4.2 ? "#ffe277" : "white" }}
                >
                  4.2 Collab Up’s Content
                </p>
                <p
                  className="normal-font-size font-weight-600 cursor-pointer"
                  onClick={() => ClickInformation("Information5", 5)}
                  style={{ color: activeLink === 5 ? "#ffe277" : "white" }}
                >
                  5. Termination
                </p>

                <p
                  className="normal-font-size font-weight-600 cursor-pointer"
                  onClick={() => ClickInformation("Information6", 6)}
                  style={{ color: activeLink === 6 ? "#ffe277" : "white" }}
                >
                  6. Limitation of Liability
                </p>
                <p
                  className="normal-font-size font-weight-600 cursor-pointer"
                  onClick={() => ClickInformation("Information7", 7)}
                  style={{ color: activeLink === 7 ? "#ffe277" : "white" }}
                >
                  7. Indemnification
                </p>
                <p
                  className="normal-font-size font-weight-600 cursor-pointer"
                  onClick={() => ClickInformation("Information8", 8)}
                  style={{ color: activeLink === 8 ? "#ffe277" : "white" }}
                >
                  8. Changes to These Terms
                </p>
                <p
                  className="normal-font-size font-weight-600 cursor-pointer"
                  onClick={() => ClickInformation("Information9", 9)}
                  style={{ color: activeLink === 9 ? "#ffe277" : "white" }}
                >
                  9. Governing Law
                </p>
                <p
                  className="normal-font-size font-weight-600 cursor-pointer"
                  onClick={() => ClickInformation("Information10", 10)}
                  style={{ color: activeLink === 10 ? "#ffe277" : "white" }}
                >
                  10. Contact Information
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-8 ">
            <div className="ms-3">
              <div className="mar-top-3">
                <p
                  className="text-color card-h-font-size font-weight-700"
                  id="Information"
                >
                  1. Introduction
                </p>
                <p className="font-size white-color">
                  Welcome to Collab Up! These Terms and Conditions govern your
                  access to and use of our services, including our website,
                  mobile applications, and any content or information provided
                  as part of these services (collectively, the "Services"). By
                  using Collab Up, you agree to comply with these Terms and
                  Conditions.
                </p>
              </div>
              <div>
                <p
                  className="text-color card-h-font-size font-weight-700"
                  id="Information2"
                >
                  2. Acceptable Use of Services
                </p>

                <p className="font-size white-color">
                  You agree to use Collab Up only for lawful purposes and in
                  accordance with these Terms and Conditions. You agree not to
                  use the Services:
                </p>
              </div>
              <div className="white-color">
                <ul>
                  <li>
                    <span>
                      To violate any applicable local, state, national, or
                      international law or regulation.
                    </span>
                  </li>
                  <li>
                    <span>
                      To engage in any conduct that is unlawful, harmful,
                      threatening, abusive, harassing, defamatory, vulgar,
                      obscene, or otherwise objectionable.
                    </span>
                  </li>
                  <li>
                    <span>
                      To impersonate or attempt to impersonate Collab Up, a
                      Collab Up employee, another user, or any other person or
                      entity.
                    </span>
                  </li>
                  <li>
                    <span>
                      To transmit or share any unsolicited or unauthorized
                      advertising, promotional materials, spam, or any other
                      form of solicitation.
                    </span>
                  </li>
                  <li>
                    <span>
                      To engage in any activity that interferes with or disrupts
                      the Services or the servers and networks connected to the
                      Services.
                    </span>
                  </li>
                  <li>
                    <span>
                      To upload, share, or otherwise make available any content
                      that infringes on the intellectual property rights of
                      others, including copyrights, trademarks, or trade
                      secrets.
                    </span>
                  </li>
                  <li>
                    <span>
                      To upload or transmit any viruses, malware, or other
                      harmful code that could damage or interfere with the
                      operation of the Services or the devices of other users.
                    </span>
                  </li>
                </ul>
              </div>
              <div>
                <p
                  className="text-color card-h-font-size  font-weight-700"
                  id="Information3"
                >
                  3. User Responsibilities
                </p>

                <p className="font-size white-color">
                  As a user of Collab Up, you are responsible for:
                </p>
              </div>
              <div className="white-color">
                <ul>
                  <li>
                    <span>
                      Maintaining the confidentiality of your account
                      information, including your password.
                    </span>
                  </li>
                  <li>
                    <span>
                      Ensuring that all information you provide to Collab Up is
                      accurate, complete, and up-to-date.
                    </span>
                  </li>
                  <li>
                    <span>
                      Complying with all applicable laws and regulations while
                      using the Services.
                    </span>
                  </li>
                  <li>
                    <span>
                      Notifying Collab Up immediately of any unauthorized use of
                      your account or any other breach of security.
                    </span>
                  </li>
                </ul>
              </div>
              <div>
                <p
                  className="text-color card-h-font-size font-weight-700"
                  id="Information4"
                >
                  4. Content and Intellectual Property
                </p>
                <p
                  className="font-size white-color font-weight-700"
                  id="Information4.1"
                >
                  4.1 Your Content
                </p>
                <p className="white-color">
                  You retain ownership of any content you submit, post, or
                  display on or through the Services. However, by submitting
                  content, you grant Collab Up a worldwide, non-exclusive,
                  royalty-free license to use, reproduce, modify, adapt,
                  publish, translate, distribute, and display such content in
                  connection with providing and promoting the Services.
                </p>
                <p
                  className="font-size white-color font-weight-700"
                  id="Information4.2"
                >
                  4.2 Collab Up’s Content
                </p>
                <p className="white-color">
                  Collab Up retains all rights, title, and interest in and to
                  the Services, including all content, software, trademarks, and
                  other intellectual property rights. You may not copy, modify,
                  distribute, sell, or lease any part of our Services or
                  included software without Collab Up’s prior written consent.
                </p>
              </div>

              <div>
                <p
                  className="card-h-font-size text-color font-weight-700"
                  id="Information5"
                >
                  5. Termination
                </p>
                <p className="white-color">
                  Collab Up reserves the right to suspend or terminate your
                  access to the Services at any time, without notice or
                  liability, for any reason, including if you violate these
                  Terms and Conditions. Upon termination, your right to use the
                  Services will immediately cease.
                </p>
              </div>
              <div>
                <p
                  className="card-h-font-size text-color font-weight-700"
                  id="Information6"
                >
                  6. Limitation of Liability
                </p>
                <p className="white-color">
                  To the maximum extent permitted by law, Collab Up shall not be
                  liable for any indirect, incidental, special, consequential,
                  or punitive damages, or any loss of profits or revenues,
                  whether incurred directly or indirectly, or any loss of data,
                  use, goodwill, or other intangible losses, resulting from:
                </p>
                <div className="white-color">
                  <ul>
                    <li>
                      <span>
                        Your access to or use of or inability to access or use
                        the Services.
                      </span>
                    </li>
                    <li>
                      <span>
                        Any conduct or content of any user on the Services.
                      </span>
                    </li>
                    <li>
                      <span>
                        Any unauthorized access, use, or alteration of your
                        content.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <p
                  className="card-h-font-size text-color font-weight-700"
                  id="Information7"
                >
                  7. Indemnification
                </p>
                <div>
                  <p className="white-color">
                    You agree to defend, indemnify, and hold harmless Collab Up,
                    its affiliates, and their respective officers, directors,
                    employees, agents, and representatives from and against any
                    claims, liabilities, damages, losses, and expenses,
                    including without limitation reasonable legal and accounting
                    fees, arising out of or in any way connected with your
                    access to or use of the Services or your violation of these
                    Terms and Conditions.
                  </p>
                </div>
                <div>
                  <p
                    className="card-h-font-size text-color font-weight-700"
                    id="Information8"
                  >
                    8. Changes to These Terms
                  </p>

                  <p className="white-color">
                    You agree to defend, indemnify, and hold harmless Collab Up,
                    its affiliates, and their respective officers, directors,
                    employees, agents, and representatives from and against any
                    claims, liabilities, damages, losses, and expenses,
                    including without limitation reasonable legal and accounting
                    fees, arising out of or in any way connected with your
                    access to or use of the Services or your violation of these
                    Terms and Conditions.
                  </p>
                </div>
              </div>
              <div>
                <p
                  className="card-h-font-size text-color font-weight-700"
                  id="Information9"
                >
                  9. Governing Law
                </p>
                <p className="white-color">
                  You agree to defend, indemnify, and hold harmless Collab Up,
                  its affiliates, and their respective officers, directors,
                  employees, agents, and representatives from and against any
                  claims, liabilities, damages, losses, and expenses, including
                  without limitation reasonable legal and accounting fees,
                  arising out of or in any way connected with your access to or
                  use of the Services or your violation of these Terms and
                  Conditions.
                </p>
              </div>
              <div>
                <p
                  className="font-size text-color font-weight-700"
                  id="Information10"
                >
                  10. Governing Law
                </p>
                <p className="white-color">
                  If you have any questions about these Terms and Conditions,
                  please contact us at:
                </p>
              </div>

              <div>
                <p className="card-h-font-size white-color font-weight-700">
                  Collab Up
                  <p className="white-color font-size font-weight-400 fst-italic">
                    <a className="white-color" href="mailto:admin@lathran.com">
                      admin@lathran.com
                    </a>
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsCondition;
