import logo from "./logo.svg";
import "./App.css";
import Header from "./Componentd/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import Landing from "./Screens/Landing";
import Footer from "./Componentd/Footer";
import Privacy from "./Screens/Privacy";
import TermsCondition from "./Screens/TermsCondition";
import "animate.css";
import ReleaseNotes from "./Screens/ReleaseNotes";
import UserGuide from "./Screens/UserGuide";
import LastFooter from "./Componentd/LastFooter";
import BookADemo from "./Screens/BookADemo";
import Layout from "./Componentd/Layout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div>
      <Routes>
        <Route element={<Layout />}>
          <Route exact path="/terms-condition" element={<TermsCondition />} />
          <Route exact path="/book-a-demo" element={<BookADemo />} />
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/release-notes" element={<ReleaseNotes />} />
          <Route exact path="/user-guide" element={<UserGuide />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
