import React from "react";
import Lottie from "lottie-react";
import animationData from "../jsons/Search.json";

const AnimationComponent = () => {
  return (
    <Lottie
      className="animation-json"
      animationData={animationData}
      loop={true}
    />
  );
};

export default AnimationComponent;
