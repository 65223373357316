import React from "react";
import LathranLogo from "../Images/Lathran-Logo.svg";

function LastFooter() {
  return (
    <div className="header-bg">
      <div className="container-fluid container-mar ">
        <div className="Last-Footer">
          <p className="white-color font-16">
            <span> CollabUp by </span>{" "}
            <span>
              <a
                className="white-color"
                target="_blank"
                href="https://www.lathran.com/"
              >
                LathranSoft
              </a>
            </span>{" "}
            <span>—bringing teams together, wherever they are.</span>
          </p>
          <a href="https://www.lathran.com/" target="_blank">
            <img className="lathran-top cus-fluid" src={LathranLogo} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default LastFooter;
