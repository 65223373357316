import { React, useEffect } from "react";
// import FaqImage from "../Images/Faq-image.png";
import { Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useState } from "react";
import MyChatBot from "../Componentd/MyChatBot";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { animateScroll as scrollAnimate } from "react-scroll";

function UserGuide() {
  const [chatVisible, setChatVisible] = useState(false);
  const [showing, setShowing] = useState(false);
  const { pathname } = useLocation();
  const location = useLocation();
  const Navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handlePdfDonwload = () => {
    console.log("handlePdfDonwload");

    const path = "/iWantHub-Web-App.pdf";
    const link = document.createElement("a");
    link.href = path;
    console.log("link: ", link);
    console.log("path: ", path);

    link.setAttribute("download", "iWantHub-Web-App.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const Contact = () => {
    setTimeout(() => {
      scrollAnimate.scrollTo(
        document.getElementById("Contact-page").offsetTop,
        {
          smooth: true,
          duration: 100,
          offset: -70,
        }
      );
    }, 500);
    setShowing(false);
  };

  return (
    <div className="header-bg custom-margin-top-bottom">
      <div className="container-fluid container-mar white-color releaase-notes-bottom-margin">
        <MyChatBot />
        <div className="text-center padding-top-4  custom-mx">
          <h3 className="fifty-two text-center font-weight-700">
            Your Go-To Guide for Seamless Collaboration
          </h3>
          <p className="font-size font-weight-400 mar-top-7">
            Ready to take teamwork to the next level? Whether you're
            streamlining communication or organizing key projects, CollabUp has
            you covered. Our step-by-step guides for mobile make it easier than
            ever to unlock every feature and get more done, faster.
          </p>
          {/* <img className="custom-image-margin img-fluid" src={FaqImage} alt="" /> */}
          <div className="res-d-flex mar-top-7">
            <Button
              variant="outline-success"
              className="btn custom-Schedule-btn me-3 fw-bold"
            >
              Dive into Mobile App Guide
            </Button>

            <Button
              variant="outline-success"
              className="btn custom-product-btn take-product"
            >
              <span>
                <svg
                  className="custom-svg-start "
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  // stroke="#FF7676"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.0071 6.12715C16.7754 7.08872 16.7754 9.57794 15.0071 10.5395L4.33051 16.3454C2.61196 17.28 0.5 16.0636 0.5 14.1393L0.500001 2.52741C0.500001 0.603069 2.61196 -0.613314 4.33051 0.32123L15.0071 6.12715Z"
                    fill="#FF7676"
                  />
                </svg>
              </span>
              <span className="ms-2 ">Start the Product Tour</span>
            </Button>
          </div>
          <hr className="white-color mar-top-4" />
        </div>
        <div>
          <div className="faq-border mar-top-4">
            <div className="faq-sub-border">
              <h3 className="font-34 font-weight-700">
                In the guide, learn how to:
              </h3>
              <div className="row">
                <div className="col-md-6  ">
                  <div className="custom-card-background-color">
                    <h5 className="font-weight-700 card-h-font-size">
                      Onboarding & User Setup
                    </h5>
                    <ul className="mx-auto ul-color ">
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Create an account in seconds.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Quick login with phone or email.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Reset your password anytime.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Take a quick app tour for new users.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Add new users with ease.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6  ">
                  <div className="custom-card-background-color">
                    <h5 className="font-weight-700 card-h-font-size">
                      User Profile & Security
                    </h5>
                    <ul className="mx-auto ul-color ">
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Edit your profile information.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Secure your account with strong passwords.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Enable two-factor authentication.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Add fingerprint login for mobile.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Manage active sessions across devices.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6  ">
                  <div className="custom-card-background-color">
                    <h5 className="font-weight-700 card-h-font-size">
                      Chat & Messaging
                    </h5>
                    <ul className="mx-auto ul-color ">
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Real-time 1:1 messaging with read receipts.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Group chats with media sharing.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Send attachments instantly.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Conduct audio and video calls.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        React to messages with emojis.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6  ">
                  <div className="custom-card-background-color">
                    <h5 className="font-weight-700 card-h-font-size">
                      Group & Channel Management
                    </h5>
                    <ul className="mx-auto ul-color ">
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Create dedicated group chats for teams.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Set permissions and roles for members.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Manage chat channels by topic or team.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Invite users with a simple link.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Add and remove members anytime.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6  ">
                  <div className="custom-card-background-color">
                    <h5 className="font-weight-700 card-h-font-size">
                      File Sharing & Collaboration
                    </h5>
                    <ul className="mx-auto ul-color ">
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Share files easily within chats.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Control access with permissions (view/edit).
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Share links for external collaboration.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Collaborate in real-time with comments.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        View shared files and documents inline.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6  ">
                  <div className="custom-card-background-color">
                    <h5 className="font-weight-700 card-h-font-size">
                      Audio & Video Calls
                    </h5>
                    <ul className="mx-auto ul-color ">
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Start one-on-one or group calls.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Switch between audio and video seamlessly.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Mute or unmute during calls.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Share your screen in video calls.
                      </li>
                      <li
                        className="ul-text py-2"
                        style={{ fontWeight: "500" }}
                      >
                        Manage call settings easily.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row genenral-sec" id="faqs">
            <p className="heading-font-size font-weight-700 white-color text-center">
              Frequently Asked Questions{" "}
            </p>
            <p className="font-weight-400 font-size white-color text-center">
              Need something cleared up? Here are our most frequently asked
              questions about the product and how it works.
            </p>
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <div className="mar-top-4">
                <Accordion eventKey="0" className="cus-accordian">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      className="card-h-font-size"
                      style={{ fontWeight: "600" }}
                    >
                      Is CollabUp free to use?
                    </Accordion.Header>
                    <Accordion.Body
                      className="ps-5 font-size"
                      style={{ fontWeight: "400" }}
                    >
                      Yes, CollabUp is completely free for all users, whether
                      for business or personal use.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header
                      className="card-h-font-size"
                      style={{ fontWeight: "600" }}
                    >
                      Who can use CollabUp?
                    </Accordion.Header>
                    <Accordion.Body
                      className="ps-5 font-size"
                      style={{ fontWeight: "400" }}
                    >
                      It’s built for business teams but works equally well for
                      personal communication.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header
                      className="card-h-font-size"
                      style={{ fontWeight: "600" }}
                    >
                      What features does CollabUp offer?
                    </Accordion.Header>
                    <Accordion.Body
                      className="ps-5 font-size"
                      style={{ fontWeight: "400" }}
                    >
                      CollabUp includes channels for organized conversations,
                      real-time messaging, audio messages, file sharing,
                      presentations, screen recordings, and replies—all
                      happening inside the chat.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header
                      className="card-h-font-size"
                      style={{ fontWeight: "600" }}
                    >
                      Can I create channels for my team?  
                    </Accordion.Header>
                    <Accordion.Body
                      className="ps-5 font-size"
                      style={{ fontWeight: "400" }}
                    >
                      Yes, you can create channels for specific teams, projects,
                      or interests to keep conversations organized.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header
                      className="card-h-font-size"
                      style={{ fontWeight: "600" }}
                    >
                      How does audio messaging work?
                    </Accordion.Header>
                    <Accordion.Body
                      className="ps-5 font-size"
                      style={{ fontWeight: "400" }}
                    >
                      You can easily send and receive audio messages within any
                      chat or channel, making communication faster and more
                      engaging.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header
                      className="card-h-font-size"
                      style={{ fontWeight: "600" }}
                    >
                      Can I share files and presentations?
                    </Accordion.Header>
                    <Accordion.Body
                      className="ps-5 font-size"
                      style={{ fontWeight: "400" }}
                    >
                      Absolutely! You can share files, images, and even present
                      right inside the chat to keep everyone on the same page.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header
                      className="card-h-font-size"
                      style={{ fontWeight: "600" }}
                    >
                      Does CollabUp support screen recording?
                    </Accordion.Header>
                    <Accordion.Body
                      className="ps-5 font-size"
                      style={{ fontWeight: "400" }}
                    >
                      Yes, CollabUp allows you to record your screen and share
                      it directly in chats for smoother collaboration.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header
                      className="card-h-font-size"
                      style={{ fontWeight: "600" }}
                    >
                      Can I reply to specific messages?
                    </Accordion.Header>
                    <Accordion.Body
                      className="ps-5 font-size"
                      style={{ fontWeight: "400" }}
                    >
                      Yes, you can reply to any message within a chat or channel
                      to keep discussions clear and relevant.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
        <p className="font-size text-center mar-top-8">
          <span className="white-color ">
            Can’t Find What You Are Looking For?
          </span>
          <span className="text-color ps-2 cursor-pointer" onClick={Contact}>
            Let’s Talk
          </span>
        </p>
      </div>
    </div>
  );
}

export default UserGuide;
