import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import MyChatBot from "../Componentd/MyChatBot";
import { animateScroll as scrollAnimate } from "react-scroll";

function Privacy() {
  const [activeLink, setActiveLink] = useState(null);

  const ClickInformation = (sectionId, LinkId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const sectionOffset = section.offsetTop;

      scrollAnimate.scrollTo(sectionOffset, {
        smooth: true,
        duration: 500,
        offset: -70,
      });
      setActiveLink(LinkId);
    }
  };

  const { pathname } = useLocation();
  const [chatVisible, setChatVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="header-bg">
      <div className="container-fluid container-mar releaase-notes-bottom-margin">
        <MyChatBot />

        <div className="text-center padding-top-4">
          <h1 className="font-64 white-color">Privacy Policy</h1>
          <p className="font-size white-color mar-top-2">
            Last updated: September 2nd, 2024
          </p>
        </div>
        <hr className="white-color mar-top-3" />
        <div className="row ">
          <div className="col-md-4">
            <div className="privacy-border  mar-top-3">
              <p
                className="normal-font-size text-color font-weight-700 cursor-pointer"
                onClick={() => ClickInformation("Information", 1)}
                style={{ color: activeLink === 1 ? "#ffe277" : "white" }}
              >
                1. Information We Collect 
              </p>
              <div className="white-color">
                <div className="cus-p">
                  <p
                    className="font-16  ps-3 font-weight-400 cursor-pointer"
                    onClick={() => ClickInformation("Information1.1", 1.1)}
                    style={{ color: activeLink === 1.1 ? "#ffe277" : "white" }}
                  >
                    1.1 Information You Provide
                  </p>
                </div>
                <p
                  className="font-16  ps-3 cursor-pointer"
                  onClick={() => ClickInformation("Information1.2", 1.2)}
                  style={{ color: activeLink === 1.2 ? "#ffe277" : "white" }}
                >
                  1.2 Information We Collect Automatically
                </p>
                <p
                  className="normal-font-size  font-weight-600 cursor-pointer"
                  onClick={() => ClickInformation("Information2", 2)}
                  style={{ color: activeLink === 2 ? "#ffe277" : "white" }}
                >
                  2. How We Use Your Information
                </p>
                <p
                  className="normal-font-size font-weight-600 cursor-pointer"
                  onClick={() => ClickInformation("Information3", 3)}
                  style={{ color: activeLink === 3 ? "#ffe277" : "white" }}
                >
                  3. How We Share Your Information 
                </p>
                <p
                  className="normal-font-size font-weight-600 cursor-pointer"
                  onClick={() => ClickInformation("Information4", 4)}
                  style={{ color: activeLink === 4 ? "#ffe277" : "white" }}
                >
                  4. Data Security
                </p>
                <p
                  className="normal-font-size font-weight-600 cursor-pointer"
                  onClick={() => ClickInformation("Information5", 5)}
                  style={{ color: activeLink === 5 ? "#ffe277" : "white" }}
                >
                  5. Your Choices 
                </p>
                <div className="cus-p">
                  <p
                    className="font-16  ps-3 font-weight-400 cursor-pointer"
                    onClick={() => ClickInformation("Information5.1", 5.1)}
                    style={{ color: activeLink === 5.1 ? "#ffe277" : "white" }}
                  >
                    5.1 Account Information
                  </p>
                  <p
                    className="font-16  ps-3 font-weight-400 cursor-pointer"
                    onClick={() => ClickInformation("Information5.2", 5.2)}
                    style={{ color: activeLink === 5.2 ? "#ffe277" : "white" }}
                  >
                    5.2 Communication Preferences 
                  </p>
                </div>
                <p
                  className="font-16  ps-3 font-weight-400 cursor-pointer"
                  onClick={() => ClickInformation("Information5.3", 5.3)}
                  style={{ color: activeLink === 5.3 ? "#ffe277" : "white" }}
                >
                  5.3 Cookies
                </p>
                <p
                  className="normal-font-size font-weight-600 cursor-pointer"
                  onClick={() => ClickInformation("Information6", 6)}
                  style={{ color: activeLink === 6 ? "#ffe277" : "white" }}
                >
                  6. Data Retention
                </p>
                <p
                  className="normal-font-size font-weight-600 cursor-pointer"
                  onClick={() => ClickInformation("Information7", 7)}
                  style={{ color: activeLink === 7 ? "#ffe277" : "white" }}
                >
                  7. Children's Privacy
                </p>
                <p
                  className="normal-font-size font-weight-600 cursor-pointer"
                  onClick={() => ClickInformation("Information8", 8)}
                  style={{ color: activeLink === 8 ? "#ffe277" : "white" }}
                >
                  8. Changes to This Privacy Policy
                </p>
                <p
                  className="normal-font-size font-weight-600 cursor-pointer"
                  onClick={() => ClickInformation("Information9", 9)}
                  style={{ color: activeLink === 9 ? "#ffe277" : "white" }}
                >
                  9. Contact Us
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-8 ">
            <div className="ms-3">
              <div className="mar-top-3">
                <p className="text-color card-h-font-size font-weight-700">
                  Introduction 
                </p>
                <p className="font-size white-color">
                  Welcome to Collab Up! We are committed to protecting your
                  privacy and ensuring that your personal information is handled
                  securely and responsibly. This Privacy Policy explains how we
                  collect, use, and disclose your information when you use our
                  services.
                </p>
              </div>
              <div>
                <p
                  className="text-color card-h-font-size font-weight-700"
                  id="Information"
                >
                  1. Information We Collect 
                </p>
                <p className=" font-size white-color" id="Information1.1">
                  1.1 Information You Provide {" "}
                </p>
                <p className="font-size white-color">
                  When you use Collab Up, we collect information you provide
                  directly to us. This includes: 
                </p>
              </div>
              <div className="white-color">
                <ul>
                  <li>
                    <span className="font-weight-700">
                      Account Information:
                    </span>
                    <span className="ps-1">
                      {" "}
                      When you create an account, we collect your name, email
                      address, password, and other relevant details. 
                    </span>
                  </li>
                  <li>
                    <span className="font-weight-700">
                      Profile Information:
                    </span>
                    <span className="ps-1">
                      {" "}
                      You may provide additional information such as your job
                      title, department, and contact details.
                    </span>
                  </li>
                  <li>
                    <span className="font-weight-700">Content</span>
                    <span className="ps-1">
                      {" "}
                      We collect messages, files, and other content you create,
                      upload, or share while using our services. 
                    </span>
                  </li>
                </ul>
              </div>
              <div className="white-color">
                <p className="font-size font-weight-700" id="Information1.2">
                  1.2 Information We Collect Automatically 
                </p>
                <p className="font-size white-color">
                  When you use Collab Up, we automatically collect certain
                  information, including: 
                </p>
              </div>
              <div className="white-color">
                <ul>
                  <li>
                    <span className="font-weight-700">Usage Information:</span>
                    <span className="ps-1">
                      {" "}
                      We collect information about your interactions with our
                      services, including the features you use, the content you
                      view, and the actions you take.
                    </span>
                  </li>
                  <li>
                    <span className="font-weight-700">
                      Device Information:{" "}
                    </span>
                    <span className="ps-1">
                      {" "}
                      We collect information about the devices you use to access
                      our services, such as your IP address, browser type, and
                      operating system. 
                    </span>
                  </li>
                  <li>
                    <span className="font-weight-700">Log Data:</span>
                    <span className="ps-1">
                      {" "}
                      We automatically collect log data, including the date and
                      time you access our services, and your interactions within
                      the app. 
                    </span>
                  </li>
                </ul>
              </div>
              <div>
                <p
                  className="text-color card-h-font-size font-weight-700"
                  id="Information2"
                >
                  2. How We Use Your Information
                </p>
                <p className="font-size white-color">
                  We use the information we collect to: 
                </p>
              </div>
              <div className="white-color">
                <ul>
                  <li>
                    <span>Provide, maintain, and improve our services. </span>
                  </li>
                  <li>
                    <span>
                      Communicate with you about your account and provide
                      customer support. 
                    </span>
                  </li>
                  <li>
                    <span>
                      Personalize your experience and show you content that is
                      relevant to you. 
                    </span>
                  </li>
                  <li>
                    <span>
                      Monitor and analyze trends, usage, and activities in
                      connection with our services.
                    </span>
                  </li>
                  <li>
                    <span>
                      Detect, prevent, and address technical issues, security
                      breaches, and fraudulent activities.
                    </span>
                  </li>
                </ul>
              </div>
              <div>
                <p
                  className="card-h-font-size text-color font-weight-700"
                  id="Information3"
                >
                  3. How We Share Your Information
                </p>
                <p className="white-color">
                  We do not share your personal information with anyone except
                  as follows: 
                </p>
                <div className="white-color">
                  <ul>
                    <li>
                      <span className="font-weight-700">
                        With Your Consent:
                      </span>
                      <span className="ps-1">
                        {" "}
                        We may share your information when you have given us
                        explicit consent to do so.
                      </span>
                    </li>
                    <li>
                      <span className="font-weight-700">
                        Legal Requirements:
                      </span>
                      <span className="ps-1">
                        {" "}
                        We may disclose your information if required to do so by
                        law or in response to valid legal processes. 
                      </span>
                    </li>
                    <li>
                      <span className="font-weight-700">
                        Business Transfers:{" "}
                      </span>
                      <span className="ps-1">
                        {" "}
                        If we are involved in a merger, acquisition, or sale of
                        all or a portion of our assets, your information may be
                        transferred as part of that transaction.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <p
                  className="card-h-font-size text-color font-weight-700"
                  id="Information4"
                >
                  4. Data Security
                </p>
                <div>
                  <p className="white-color">
                    We take the security of your personal information seriously
                    and implement appropriate technical and organizational
                    measures to protect it. However, no method of transmission
                    over the internet or electronic storage is completely
                    secure, and we cannot guarantee absolute security. 
                  </p>
                </div>
                <div>
                  <p
                    className="card-h-font-size text-color font-weight-700"
                    id="Information5"
                  >
                    5. Your Choices 
                  </p>
                  <p
                    className="font-size white-color font-weight-700"
                    id="Information5.1"
                  >
                    5.1 Account Information 
                  </p>
                  <p className="white-color">
                    You can update, correct, or delete your account information
                    at any time by accessing your account settings. If you wish
                    to delete your account entirely, please contact our support
                    team.
                  </p>
                </div>
                <div>
                  <p
                    className="font-size white-color font-weight-700"
                    id="Information5.2"
                  >
                    5.2 Communication Preferences 
                  </p>
                  <p className="white-color">
                    You may opt out of receiving promotional communications from
                    us by following the unsubscribe instructions in those
                    communications or by adjusting your account settings. 
                  </p>
                </div>
                <div>
                  <p
                    className="font-size white-color font-weight-700"
                    id="Information5.3"
                  >
                    5.3 Cookies 
                  </p>
                  <p className="white-color">
                    Most web browsers are set to accept cookies by default. You
                    can adjust your browser settings to remove or reject
                    cookies; however, doing so may affect the functionality of
                    our services. 
                  </p>
                </div>
              </div>
              <div>
                <p
                  className="card-h-font-size text-color font-weight-700"
                  id="Information6"
                >
                  6. Data Retention 
                </p>
                <p className="white-color">
                  We retain your personal information for as long as necessary
                  to provide our services and fulfill the purposes outlined in
                  this Privacy Policy unless a longer retention period is
                  required by law. 
                </p>
              </div>
              <div>
                <p
                  className="card-h-font-size text-color font-weight-700"
                  id="Information7"
                >
                  7. Children's Privacy
                </p>
                <p className="white-color">
                   Collab Up is not intended for use by individuals under the
                  age of 16. We do not knowingly collect personal information
                  from children under 16. If we become aware that we have
                  collected information from a child under 16, we will take
                  steps to delete such information. 
                </p>
              </div>
              <div>
                <p
                  className="card-h-font-size text-color font-weight-700"
                  id="Information8"
                >
                  8. Changes to This Privacy Policy
                </p>
                <p className="white-color">
                  We may update this Privacy Policy from time to time to reflect
                  changes in our practices or for other operational, legal, or
                  regulatory reasons. We will notify you of any significant
                  changes by posting the new Privacy Policy on our website and
                  updating the effective date at the top. 
                </p>
              </div>
              <div>
                <p
                  className="card-h-font-size text-color font-weight-700"
                  id="Information9"
                >
                  9. Contact Us 
                </p>
                <p className="white-color">
                  If you have any questions about this Privacy Policy, please
                  contact us at: 
                </p>
                <p className="card-h-font-size white-color font-weight-700">
                  Collab Up 
                  <p className="white-color font-size font-weight-400 fst-italic">
                    <a className="white-color" href="mailto:admin@lathran.com">
                      admin@lathran.com
                    </a>
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
