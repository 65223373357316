import React, { useEffect } from "react";
import { InlineWidget } from "react-calendly";
import { useLocation } from "react-router-dom";

function BookADemo() {
  const { pathname } = useLocation;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="header-bg">
      <div className="container-fluid container-calendary white-color">
        <div className="row">
          <div className="col-lg-5 calendary-bg">
            <div className="">
              <p className="normal-font-size font-weight-400">
                Experience how Collab Up can revolutionize the way your team
                communicates and collaborates. Schedule a demo with our team,
                and discover how Collab Up can help you:
              </p>
            </div>
            <div className="normal-font-size font-weight-600 py-3">
              <p>
                ✅ Centralize all your team’s communication in one place with
                real-time messaging.
              </p>
              <p>
                ✅ Collaborate effortlessly across channels dedicated to
                specific projects, teams, or topics.
              </p>
              <p>
                ✅ Share files and documents securely, with easy access for
                everyone in your team.
              </p>
              <p>
                ✅ Integrate with the tools your team already uses to streamline
                workflows.
              </p>
              <p>
                ✅ Customize notifications and settings to keep your team
                focused and organized.
              </p>
            </div>
            <div className="font-weight-400">
              <p>
                Imagine the possibilities when your team is perfectly in sync.
                With Collab Up, you can break down communication barriers,
                accelerate project timelines, and foster a culture of
                collaboration and innovation. Explore the future of teamwork
                with Collab Up and unlock your team's full potential.
              </p>
            </div>
          </div>
          <div className="col-lg-7">
            <InlineWidget
              className="calendly-inline-widget"
              url="https://calendly.com/lathransoft/book-your-slot-now"
              /*
               * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
               * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
               */
              rootElement={document.getElementById("root")}
              text="Click here to schedule!"
              textColor="#ffffff"
              color="#00a2ff"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookADemo;
