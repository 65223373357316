import React, { useState } from "react";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import MyChatBot from "../Componentd/MyChatBot";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ReleaseNotes() {
  const [activeTab, setActiveTab] = useState("/home");

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleSelect = (selectedKey) => {
    setActiveTab(selectedKey);
  };

  const [chatVisible, setChatVisible] = useState(false);

  useEffect(() => {
    const footer = document.querySelector(".footer");
    const releaseMargin = document.querySelector(".release-margin");

    if (!footer || !releaseMargin) {
      console.error("Footer or release margin element not found.");
      return;
    }

    const handleScroll = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          releaseMargin.style.position = "absolute";
          releaseMargin.style.bottom = "0";
        } else {
          releaseMargin.style.position = "fixed";
          releaseMargin.style.bottom = "auto";
        }
      });
    };

    const observer = new IntersectionObserver(handleScroll, {
      root: null,
      threshold: 0,
    });

    observer.observe(footer);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className=" header-bg releaase-notes-bottom-margin">
      <div className="container-fluid container-mar white-color padding-top-4">
        <MyChatBot />

        <h3 className="font-64 text-center  ">Latest Product Updates</h3>
        <hr className="mar-top-4" />
        <Tab.Container
          defaultActiveKey="/home"
          activeKey={activeTab}
          onSelect={handleSelect}
        >
          <Row>
            <Col sm={4}>
              <Nav className="flex-column release-margin" id="release-nav">
                <Row>
                  {/* <Col sm={1}></Col> */}
                  <Col sm={12}>
                    <p
                      className="card-h-font-size text-center"
                      style={{ fontWeight: "800" }}
                    >
                      Release Notes
                    </p>
                    <Nav.Item className="top">
                      <Nav.Link
                        className="Feather-font-bold text-center white-color"
                        eventKey="/home"
                      >
                        <span className="white-color">Android</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className="Feather-font-bold white-color text-center my-2"
                        eventKey="link-1"
                        disabled
                      >
                        <span className="white-color"> IOS</span>{" "}
                        <span className="text-color ">(Coming Soon)</span>
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link
                        className="Feather-font-bold text-center white-color"
                        eventKey="link-2"
                      >
                        <span className="white-color">Android</span>
                      </Nav.Link>
                    </Nav.Item> */}
                  </Col>

                  {/* <Col sm={6}></Col> */}
                </Row>
              </Nav>
            </Col>
            <Col sm={8}>
              <Tab.Content>
                <Tab.Pane eventKey="/home">
                  <div className="font-size">
                    <h4 className="genenral-sec text-color font-32 font-weight-700">
                      November 2024 Release
                    </h4>
                    <p className="no-margin-bottom mt-4 font-size font-weight-700">
                      1. Account Setup & Onboarding
                    </p>
                    <ul>
                      {" "}
                      <li>
                        <span className="fw-bold normal-font-size">
                          Streamlined Signup:
                        </span>
                        <span className="ps-1 normal-font-size">
                          Users can now sign up using their phone number or
                          email, making account creation smoother and more
                          flexible.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          OTP Verification:
                        </span>
                        <span className="ps-1 normal-font-size">
                          Securely verify your account with an OTP. If it
                          expires, simply request a new one.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Profile Setup:
                        </span>
                        <span className="ps-1 normal-font-size">
                          Customize your profile by adding a username, avatar,
                          and other details for a personalized experience.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Guided Tour:
                        </span>
                        <span className="ps-1 normal-font-size">
                          New users are welcomed with an interactive guide that
                          helps them explore key features of the app.
                        </span>
                      </li>
                    </ul>
                    <p className="no-margin-bottom mt-4 font-size font-weight-700">
                      2. Chat & Messaging Enhancements
                    </p>
                    <ul>
                      {" "}
                      <li>
                        <span className="fw-bold normal-font-size">
                          Real-Time Messaging:
                        </span>
                        <span className="ps-1 normal-font-size">
                          Engage in one-on-one or group chats with instant
                          message delivery and read receipts.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Message Reactions:
                        </span>
                        <span className="normal-font-size ps-1">
                          Respond quickly with emoji reactions to messages,
                          making communication more expressive.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Message Editing & Deletion:
                        </span>
                        <span className="normal-font-size ps-1">
                          Correct or update sent messages, and delete them for
                          all participants when needed.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Pinned Conversations:
                        </span>
                        <span className="normal-font-size ps-1">
                          Keep important chats at the top for easy access to
                          high-priority conversations.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Unread Message Marker:
                        </span>
                        <span className="normal-font-size ps-1">
                          Mark messages as unread to revisit them later,
                          ensuring nothing is missed.
                        </span>
                      </li>
                    </ul>
                    <p className="no-margin-bottom mt-4 font-size font-weight-700">
                      3. Group Chat & Channel Features
                    </p>
                    <ul>
                      {" "}
                      <li>
                        <span className="fw-bold normal-font-size">
                          Create and Manage Groups:
                        </span>
                        <span className="ps-1 normal-font-size ps-1">
                          Easily create group chats with customizable names and
                          permissions.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Group Chat Indicators:
                        </span>
                        <span className="normal-font-size ps-1">
                          See a placeholder in new groups where no messages have
                          been sent yet.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Add Members:
                        </span>
                        <span className="normal-font-size ps-1">
                          Seamlessly invite new participants to both individual
                          and group chats for expanded conversations.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Channel Creation:
                        </span>
                        <span className="normal-font-size ps-1">
                          Create dedicated channels for specific topics or
                          teams, improving collaboration.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Text & Media Sharing:
                        </span>
                        <span className="normal-font-size ps-1">
                          Share text, images, files, and other media with
                          participants across channels or groups.
                        </span>
                      </li>
                    </ul>
                    <div>
                      <p className="no-margin-bottom mt-4 font-size font-700">
                        4. Call & Meeting Features
                      </p>
                      <ul>
                        {" "}
                        <li>
                          <span className="fw-bold font-size">
                            Audio & Video Calls:
                          </span>
                          <span className="ps-1">
                            Start one-on-one or group audio and video calls
                            directly from the chat.
                          </span>
                        </li>
                        <li>
                          <span className="fw-bold">Call Management:</span>
                          <span className="ps-1">
                            Mute your microphone or stop video during calls to
                            switch between modes seamlessly.
                          </span>
                        </li>
                        <li>
                          <span className="fw-bold">Screen Sharing:</span>
                          <span className="ps-1">
                            Share your screen during meetings to collaborate
                            more effectively.
                          </span>
                        </li>
                        <li>
                          <span className="fw-bold">Meeting Invitations:</span>
                          <span className="ps-1">
                            Schedule and invite participants to meetings through
                            a quick modal window.
                          </span>
                        </li>
                        <li>
                          <span className="fw-bold">
                            Call History & Management:
                          </span>
                          <span className="ps-1">
                            Access recent call logs, including missed, outgoing,
                            and received calls, and manage them easily.
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <p className="no-margin-bottom mt-4 font-size font-700">
                        5. User Interface & Experience Improvements
                      </p>
                      <ul>
                        {" "}
                        <li>
                          <span className="fw-bold font-size">
                            Activity Status:
                          </span>
                          <span className="ps-1">
                            Set your status (online, away, busy) so others can
                            see your availability in real-time.
                          </span>
                        </li>
                        <li>
                          <span className="fw-bold">Search & Filters:</span>
                          <span className="ps-1">
                            Use search to find contacts or messages quickly and
                            apply filters for better chat and call management.
                          </span>
                        </li>
                        <li>
                          <span className="fw-bold">Auto-Suggestions:</span>
                          <span className="ps-1">
                            Experience improved typing with auto-suggestions for
                            faster and more efficient messaging.
                          </span>
                        </li>
                        <li>
                          <span className="fw-bold">Announcement Layout:</span>
                          <span className="ps-1">
                            Stay updated with important app announcements
                            through a dedicated section in the app.
                          </span>
                        </li>
                        <li>
                          <span className="fw-bold">Full-Screen Viewing:</span>
                          <span className="ps-1">
                            View images and media in full-screen mode for a
                            better visual experience.
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <p className="no-margin-bottom mt-4 font-size font-weight-700">
                        6. Security & Account Management
                      </p>
                      <ul>
                        {" "}
                        <li>
                          <span className="fw-bold font-size">
                            Password Management:
                          </span>
                          <span className="ps-1">
                            Set a secure password and change it anytime to
                            protect your account.
                          </span>
                        </li>
                        <li>
                          <span className="fw-bold">
                            Profile Customization:
                          </span>
                          <span className="ps-1">
                            Update personal details like your username, avatar,
                            and status at any time.
                          </span>
                        </li>
                        <li>
                          <span className="fw-bold">
                            Log Out & Session End:
                          </span>
                          <span className="ps-1">
                            Log out securely and end your session, returning to
                            the sign-in screen
                          </span>
                        </li>
                        <li>
                          <span className="fw-bold">Account Deletion:</span>
                          <span className="ps-1">
                            Users can now permanently delete their account,
                            removing all associated data.
                          </span>
                        </li>
                        <li>
                          <span className="fw-bold">Terms & Conditions:</span>
                          <span className="ps-1">
                            Easily access and review the app's terms and
                            conditions to stay informed.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="link-1">
                  <div>
                    <h3>Option 2 Content</h3>
                    <p>This is the content for Option 2.</p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="link-2">
                  <div className="font-size">
                    <h4 className="genenral-sec april-text Feather-font-bold">
                      November 2024 Release
                    </h4>
                    <p className="no-margin-bottom mt-4 fw-bold OpenSans normal-font-size">
                      Fast and Simple Mobile Onboarding
                    </p>
                    <ul>
                      {" "}
                      <li>
                        <span className="fw-bold normal-font-size">
                          Quick Mobile Signup:
                        </span>
                        <span className="ps-1 normal-font-size">
                          Get started instantly on mobile with Google, Facebook,
                          or email—no complex setup required.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Password Recovery On-the-Go:
                        </span>
                        <span className="ps-1 normal-font-size">
                          Forgot your password? Reset it effortlessly from your
                          mobile device.
                        </span>
                      </li>
                    </ul>
                    <p className="no-margin-bottom mt-4 fw-bold OpenSans normal-font-size">
                      Mobile File Organization
                    </p>
                    <ul>
                      {" "}
                      <li>
                        <span className="fw-bold normal-font-size">
                          Search Anytime, Anywhere:
                        </span>
                        <span className="ps-1 normal-font-size">
                          Use the mobile search feature to find files fast, no
                          matter where you are.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Filter for Quick Results:
                        </span>
                        <span>
                          Narrow your search with mobile filters by file type,
                          date, or size.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Sort Files:{" "}
                        </span>
                        <span className="normal-font-size">
                          Organize files by name, size, date, or type directly
                          from your phone.
                        </span>
                      </li>
                    </ul>
                    <p className="no-margin-bottom mt-4 fw-bold OpenSans normal-font-size">
                      File Viewing and Favorites
                    </p>
                    <ul>
                      {" "}
                      <li>
                        <span className="fw-bold normal-font-size">
                          Mobile Grid & List Views:
                        </span>
                        <span className="ps-1 normal-font-size">
                          View your files in grid or list format, just like on
                          the web, but optimized for mobile.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Quick File Previews:{" "}
                        </span>
                        <span className="normal-font-size">
                          Get a quick look at files before opening them with the
                          mobile preview feature.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Favorite Files:{" "}
                        </span>
                        <span className="normal-font-size">
                          Mark files as favorites on mobile for instant access
                          anytime you need them.
                        </span>
                      </li>
                    </ul>
                    <p className="no-margin-bottom mt-4 fw-bold OpenSans normal-font-size">
                      Mobile Bulk Actions
                    </p>
                    <ul>
                      {" "}
                      <li>
                        <span className="fw-bold normal-font-size">
                          Batch File Management:
                        </span>
                        <span className="ps-1 normal-font-size">
                          Move, delete, or share multiple files at once with
                          easy mobile controls.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Batch Downloads:{" "}
                        </span>
                        <span>
                          Download multiple files on the go to ensure you have
                          everything you need, even offline.
                        </span>
                      </li>
                    </ul>
                    <p className="no-margin-bottom mt-4 fw-bold OpenSans normal-font-size">
                      File Sharing & Collaboration on Mobile
                    </p>
                    <ul>
                      {" "}
                      <li>
                        <span className="fw-bold normal-font-size">
                          Share on the Go:
                        </span>
                        <span className="ps-1 normal-font-size">
                          Generate shareable links or send files directly from
                          your mobile device, making collaboration effortless.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          View File Details:
                        </span>
                        <span className="ps-1 normal-font-size">
                          Check important metadata before sharing to ensure
                          accuracy.
                        </span>
                      </li>
                    </ul>
                    <p className="no-margin-bottom mt-4 fw-bold OpenSans normal-font-size">
                      Mobile Archive & Recovery
                    </p>
                    <ul>
                      {" "}
                      <li>
                        <span className="fw-bold normal-font-size">
                          Archive for Long-Term Storage:
                        </span>
                        <span className="ps-1 normal-font-size">
                          Archive files directly from your mobile device and
                          unarchive them when you need them back.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Recover Deleted Files:{" "}
                        </span>
                        <span className="normal-font-size">
                          Access your recycle bin on mobile and restore files
                          with just a tap.
                        </span>
                      </li>
                    </ul>
                    <p className="no-margin-bottom mt-4 fw-bold OpenSans normal-font-size">
                      Mobile Personalization & Security
                    </p>
                    <ul>
                      {" "}
                      <li>
                        <span className="fw-bold normal-font-size">
                          Light & Dark Mode:
                        </span>
                        <span className="ps-1 normal-font-size">
                          Switch between light and dark modes on mobile, making
                          file management easy on your eyes.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Secure Password Changes:
                        </span>
                        <span className="normal-font-size">
                          Update your password and access security settings from
                          your mobile device to ensure peace of mind
                        </span>
                      </li>
                    </ul>
                    <p className="no-margin-bottom mt-4 fw-bold OpenSans normal-font-size">
                      Mobile Account Management
                    </p>
                    <ul>
                      {" "}
                      <li>
                        <span className="fw-bold normal-font-size">
                          Version Control:
                        </span>
                        <span className="ps-1 normal-font-size">
                          Keep your mobile app updated with the latest features
                          and improvements by checking your app version.
                        </span>
                      </li>
                      <li>
                        <span className="fw-bold normal-font-size">
                          Account Deactivation & Deletion:
                        </span>
                        <span className="ps-1 normal-font-size">
                          Manage your account from mobile—temporarily deactivate
                          it or delete it permanently when needed.
                        </span>
                      </li>
                    </ul>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
}

export default ReleaseNotes;
